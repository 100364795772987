import React from 'react'

import Parallax from '../parallax'
import Header from '../header'
import Footer from '../footer'
import BuyModal from '../buy-modal'

type Props = {
  children?: React.ReactChild
}

// eslint-disable-next-line react/prop-types
const Page: React.FC<Props> = ({ children }) => {
  return (
    <>
      <Header/>
      <div className="content-wrapper">
        <div className="content">
          {children}
          <Footer/>
        </div>
      </div>
     <Parallax/>
     <BuyModal/>
    </>
  )
}

export default Page
