import create from 'zustand'

/* eslint-disable no-unused-vars */
export enum BuyModalTypes {
  None = 'None',
  ServiceSelect = 'ServiceSelect',
  AmountSelect = 'AmountSelect'
}
/* eslint-enable no-unused-vars */

interface IBuyModalState {
  boyModalType: BuyModalTypes
  setBuyModalType: (buyModalType: BuyModalTypes) => void
}

const useBuyModalStore = create<IBuyModalState>(set => ({
  boyModalType: BuyModalTypes.None,
  setBuyModalType: (newBuyModalType: BuyModalTypes) => set({ boyModalType: newBuyModalType })
}))

export default useBuyModalStore
