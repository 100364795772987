import React, { useEffect, useState } from 'react'
import './styles.css'

const Parallax = () => {
  const [scrollPercent, setScrollPercent] = useState(0)

  const handleScroll = () => {
    const scrollTop = window.scrollY
    const docHeight = document.body.offsetHeight
    const winHeight = window.innerHeight
    setScrollPercent(scrollTop / (docHeight - winHeight))
  }

  useEffect(() => {
    window.addEventListener('scroll', handleScroll)
    return () => { window.removeEventListener('scroll', handleScroll) }
  }, [])

  return (
    <>
    <div className="bg-parallax" style={{ marginBottom: `-${450 * (1 - scrollPercent)}px` }}></div>
      <div className="coin-parallax" style={{ marginTop: `-${3000 * scrollPercent}px` }}>
        <div className="background-coins-wrapper">
          <div className="background-coin-1"><img src="content/background-coins/1.png" alt="Background coin 1" /></div>
          <div className="background-coin-2"><img src="content/background-coins/2.png" alt="Background coin 2" /></div>
          <div className="background-coin-3"><img src="content/background-coins/3.png" alt="Background coin 3" /></div>
          <div className="background-coin-4"><img src="content/background-coins/4.png" alt="Background coin 4" /></div>
        </div>
      </div>
    </>
  )
}

export default Parallax
