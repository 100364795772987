import React, { useState, useEffect } from 'react'
import { Link } from 'react-router-dom'
import './styles.css'

import useBuyModalStore, { BuyModalTypes } from '../../services/buy-modal-store'

const Header = () => {
  const [isMobileMenuDisplayed, setMobileMenuDisplayed] = useState(false)
  const setBuyModalType = useBuyModalStore(state => state.setBuyModalType)
  const [scrollPercent, setScrollPercent] = useState(0)

  const handleScroll = () => {
    const scrollTop = window.scrollY
    const docHeight = document.body.offsetHeight
    const winHeight = window.innerHeight
    setScrollPercent(scrollTop / (docHeight - winHeight))
  }

  useEffect(() => {
    window.addEventListener('scroll', handleScroll)
    return () => { window.removeEventListener('scroll', handleScroll) }
  }, [])

  return (
    <>
      <div className={`header-wrapper ${scrollPercent === 0 ? 'init' : ''}`}>
        <div className="header">
          <Link to="/" className="logo"><img className="glow" src="content/logo-s.png" alt="Header logo"/></Link>
          <a href="/litepaper.pdf" className="link color-link">Litepaper</a>
          <Link to="/buy" className="link color-link">How To Buy</Link>
          <Link to="/#roadmap" className="link color-link">Roadmap</Link>
          <a href="#" className="link color-link">FAQ</a>
          <div
            className="right-link fill-btn fill-btn-rose"
            onClick={() => { setBuyModalType(BuyModalTypes.ServiceSelect) }}
          >Wallet Connect</div>
          <div
            className="menu-button"
            onClick={() => { setMobileMenuDisplayed(!isMobileMenuDisplayed) }}
          >
            <img
              src={isMobileMenuDisplayed ? 'content/close.svg' : 'content/menu.svg'}
              alt="Mobile Menu Button"
            />
          </div>
        </div>
      </div>
      <div className={`mobile-nav${isMobileMenuDisplayed ? ' active' : ''}`}>
        <a
          onClick={() => { setMobileMenuDisplayed(false) }}
          href="/litepaper.pdf"
          className="mobile-nav-link"
        >Litepaper</a>
        <Link
          onClick={() => { setMobileMenuDisplayed(false) }}
          to="/buy"
          className="mobile-nav-link"
        >How To Buy</Link>
        <Link
          onClick={() => { setMobileMenuDisplayed(false) }}
          to="/#roadmap"
          className="mobile-nav-link"
        >Roadmap</Link>
        <a
          onClick={() => { setMobileMenuDisplayed(false) }}
          href="#"
          className="mobile-nav-link"
        >FAQ</a>
      </div>
    </>
  )
}

export default Header
