import React, { Suspense } from 'react'
import {
  Route,
  Switch,
  BrowserRouter as Router
} from 'react-router-dom'

import Page from './page'

import './styles.css'

const Home = React.lazy(() => import('../home'))
const HowToBuy = React.lazy(() => import('../how-to-buy'))
const TermsOfService = React.lazy(() => import('../terms-of-service'))
const Privacy = React.lazy(() => import('../privacy'))

const App = () => {
  return (
    <Router>
      <Switch>
        <Route exact path='/'>
          <Suspense fallback={<div></div>}><Page><Home /></Page></Suspense>
        </Route>
        <Route exact path='/buy'>
          <Suspense fallback={<div></div>}><Page><HowToBuy /></Page></Suspense>
        </Route>
        <Route exact path='/terms'>
          <Suspense fallback={<div></div>}><Page><TermsOfService /></Page></Suspense>
        </Route>
        <Route exact path='/privacy'>
          <Suspense fallback={<div></div>}><Page><Privacy /></Page></Suspense>
        </Route>
      </Switch>
    </Router>
  )
}

export default App
