import React from 'react'
import Modal from '@material-ui/core/Modal'
import Backdrop from '@material-ui/core/Backdrop'

import useBuyModalStore, { BuyModalTypes } from '../../services/buy-modal-store'

const BuyModal = () => {
  const boyModalType = useBuyModalStore(state => state.boyModalType)
  const setBuyModalType = useBuyModalStore(state => state.setBuyModalType)

  return (
    <Modal
      aria-labelledby="buy-modal-title"
      aria-describedby="buy-modal-description"
      open={boyModalType !== BuyModalTypes.None}
      onClose={() => { setBuyModalType(BuyModalTypes.None) }}
      BackdropComponent={Backdrop}
      className="modal"
    >
      <div className="modal-content">
        {
          boyModalType === BuyModalTypes.ServiceSelect
            ? <div className="service-select">
                <div className="service-select-item accent-btn accent-btn-transp">
                  <img src="content/services/metamask.png" alt="Metamask" />Metamask
                </div>
                <div className="service-select-item accent-btn accent-btn-transp">
                  <img src="content/services/walletconnect.png" alt="Walletconnect" />
                  Walletconnect</div>
                <div className="service-select-item accent-btn accent-btn-transp">
                  <img src="content/services/pancakeswap.png" alt="Pancakeswap" />Pancakeswap
                </div>
              </div>
            : boyModalType === BuyModalTypes.AmountSelect
              ? <div className="amount-select">
                  <div className="amount-select-row">
                    <div
                      className="amount-select-item accent-btn accent-btn-transp"
                      onClick={() => {
                        setBuyModalType(BuyModalTypes.ServiceSelect)
                      }}
                    >100M $Piggy</div>
                    <div
                      onClick={() => {
                        setBuyModalType(BuyModalTypes.ServiceSelect)
                      }}
                      className="amount-select-item accent-btn accent-btn-transp"
                    >500M $Piggy</div>
                  </div>
                  <div className="amount-select-row">
                  <div
                      className="amount-select-item accent-btn accent-btn-transp"
                      onClick={() => {
                        setBuyModalType(BuyModalTypes.ServiceSelect)
                      }}
                    >1B $Piggy</div>
                    <div
                      onClick={() => {
                        setBuyModalType(BuyModalTypes.ServiceSelect)
                      }}
                      className="amount-select-item accent-btn accent-btn-transp"
                    >5B $Piggy</div>
                  </div>
                  <div className="amount-select-row">
                  <div
                      className="amount-select-item accent-btn accent-btn-transp"
                      onClick={() => {
                        setBuyModalType(BuyModalTypes.ServiceSelect)
                      }}
                    >10B $Piggy</div>
                    <div
                      onClick={() => {
                        setBuyModalType(BuyModalTypes.ServiceSelect)
                      }}
                      className="amount-select-item accent-btn accent-btn-transp"
                    >50B $Piggy</div>
                  </div>
                </div>
              : <div></div>
        }
        <img
          className="modal-close-btn"
          src='content/close.svg'
          alt="Close modal"
          onClick={() => { setBuyModalType(BuyModalTypes.None) }}
        />
      </div>
    </Modal>
  )
}

export default BuyModal
