import React from 'react'
import { Link } from 'react-router-dom'
import './styles.css'

const Footer = () => {
  return (
    <div className="footer">
      <div className="partners">
        <a href="https://www.bscscan.com/token/0xcd2ecd5e06b1a330789b30e8ada3d11c51503a71"><img src="content/partners/1.png" alt="BscScan" /></a>
        <a href="#"><img src="content/partners/2.png" alt="CoinMarketCap" /></a>
        <a href="https://coinmarketcap.com/currencies/piggy-bank-token/"><img src="content/partners/3.png" alt="nomics" /></a>
        <a href="https://www.coingecko.com/en/coins/piggy-bank-token"><img src="content/partners/4.png" alt="CoinGecko" /></a>
      </div>
      <div className="links">
        <Link to="/terms">TOS</Link>
        <Link to="/privacy">Privacy</Link>
        <a href="/sitemap.xml">PiggyBankToken.com Sitemap</a>
        <div>© 2021 PiggyBankToken. All Rights Reserved.</div>
      </div>
      <div className="social">
        <a href="https://twitter.com/piggybanktoken" className="color-link">
          <i className="fab fa-twitter"></i>
        </a>
        <a href="https://github.com/piggybanktoken" className="color-link">
        <i className="fab fa-github"></i>
        </a>
        <a href="https://www.reddit.com/r/PiggyBankToken" className="color-link">
          <i className="fab fa-reddit-alien"></i>
        </a>
        <a href="https://www.facebook.com/piggybanktoken" className="color-link">
        <i className="fab fa-facebook-square"></i>
        </a>
        <a href="https://t.me/PiggyBankToken" className="color-link">
        <i className="fab fa-youtube"></i>
        </a>
        <a href="https://www.instagram.com/piggybanktoken_official" className="color-link">
        <i className="fab fa-instagram"></i>
        </a>
        <a href="https://discord.gg/piggybanktoken" className="color-link">
        <i className="fab fa-discord"></i>
        </a>
      </div>
    </div>
  )
}

export default Footer
